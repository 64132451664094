import { LOCATION_PRE_PROCESSOR_STATUS} from '../../../misc/commons'
import locationpreprocessorServices from '../../../services/notisphere/locationpreprocessor'
import orgService from '../../../services/notisphere/organization'
import moment from 'moment-timezone'
import {FilterConnections_Key} from '../../../misc/tableHeaders'
export default {
    props: {

    },

    data() {
        return {
            total: 0,
            items: [],
            searchQuery: {},
            search: {
                status: null,
                category: null,
                supplierName: null,
            },
            status: null,
            locationPreProcessor_status: LOCATION_PRE_PROCESSOR_STATUS,
            supplierOrgId: null,
            listSupplier: [],
            header: [
                {
                    label: 'Job Name',
                    name: 'name',
                    sort: true,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                },
                {
                    label: 'Supplier Name',
                    name: 'supplierName',
                    sort: true,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                },
                {
                    label: 'Comments',
                    name: 'comment',
                    sort: true,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                },
                {
                    label: 'Status',
                    name: 'status',
                    sort: true,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left'
                },
                {
                    label: 'Last Modified by',
                    name: 'modifiedUserName',
                    sort: true,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left'
                },
                {
                    label: 'Last Update',
                    name: 'modifiedDatetime',
                    sort: true,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left'
                },
                {
                    label: '',
                    name: 'action',
                    row_text_alignment: 'text-center',
                    column_text_alignment: 'text-center'
                }
            ],
            SavedAllFilterConnections: this.$store.state.SavedAllFilterConnections.filter(s=>s.user_id==this.$store.state.user.id)[0],
            FilterConnections_Data: JSON.parse( localStorage.getItem(FilterConnections_Key))?.filter(s=>s.user_id==this.$store.state.user.id)[0],
        }
    },
    mounted() {
        this.findSuppliers()
        if(this.FilterConnections_Data!=null && this.FilterConnections_Data!=undefined)
        {
            if(this.FilterConnections_Data.SavedNotisphereConnections.Page_LocationPreProcessor!=null)
            {
                this.search.status=this.FilterConnections_Data.SavedNotisphereConnections.Page_LocationPreProcessor.status
                this.search.supplierName=this.FilterConnections_Data.SavedNotisphereConnections.Page_LocationPreProcessor.supplierName
            }
        }
    },
    methods: {
        findSuppliers() {
            locationpreprocessorServices.getLocationPreProcessorSuppliers().then(resp => {
                this.listSupplier = resp.data.d
            })
        },
        addMoreClick() {
            this.$router.push({ name: 'noti_LocationPreProcessor_ItemDetail' })
        },
        addknownProviderClick() {
            this.$router.push({ name: 'noti_Known_Provider' })
        },
        fetchData() {
            this.SavedAllFilterConnections.SavedNotisphereConnections.Page_LocationPreProcessor.status=this.search.status
            this.SavedAllFilterConnections.SavedNotisphereConnections.Page_LocationPreProcessor.supplierName=this.search.supplierName
            localStorage.setItem(FilterConnections_Key,JSON.stringify(this.$store.state.SavedAllFilterConnections))
            locationpreprocessorServices.findLocationPreprocessor({
                ...this.searchQuery,
                sortField: this.searchQuery.sort[0] ? this.searchQuery.sort[0].name : null,
                sortDirection: this.searchQuery.sort[0] ? this.searchQuery.sort[0].order : null,
                ...this.search
            }).then(resp=>{
                if(!resp.error) {
                    this.items = resp.data.d.c
                    this.total = resp.data.d.t
                }
            })
        },
        viewJob(item) {
            this.$router.push({ name: 'noti_Location_preProcessorView', params: { id: item.preProcessorId } })
        },
        editJob(item, e) {
            this.$router.push({ name: 'noti_LocationPreProcessor_EditItem', params: { id: item.preProcessorId } })
            e.stopPropagation()
        },
        deleteJob(item, e = null) {
            this._showConfirm('Are you sure you want to deactivate the job, this cannot be undone?', { title: 'Deactivate Job', variant: 'danger' }).then(confirm => {
                if (confirm) {
                    locationpreprocessorServices.deleteLocationPreProcessorJob(item.preProcessorId).then(resp => {
                        if (!resp.error) {
                            this.fetchData()
                            this._showToast('Location Pre-Processor job Deleted!')
                        }
                    })
                }
            })
            if (e) {
                e.stopPropagation()
            }
        },
    }
}